import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import CloseButton from "react-bootstrap/CloseButton"
import Form from "react-bootstrap/Form"
import ReCAPTCHA from "react-google-recaptcha"
import toast from "react-hot-toast"
import { connect } from "react-redux"
import { useCreateApplicationMutation } from "../../redux/mediacore/jobs"

const FormJobs = props => {
  const {
    jobData,
    stateProp,
    captcha,
    closeBtnAction,
    clientName,
    formText,
    clientId,
  } = props
  const [disabled, setDisabled] = useState(true)
  const blankData = {
    position: jobData?.id,
    location: "",
    gender: "No deseo especificar",
    email: "",
    phone: "",
    extra_info: { visualizedSteps: [0] },
    languages: {},
    interviews: {},
    portfolio: {},
    status: "En revisión",
    birth_date: "",
    cv: null,
    letter: "",
  }
  const [data, setData] = useState(blankData)

  const [createApplication, { isLoading: isLoadingApplication }] =
    useCreateApplicationMutation()

  const handleInputChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }
  const formatDateToISOString = dateString => {
    if (!dateString) {
      return ""
    }
    const date = new Date(dateString)
    date.setUTCHours(12)
    const formattedDate = date.toISOString().slice(0, -5) + "Z"
    return formattedDate
  }
  const handleDateChange = e => {
    e.persist()
    setData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }

  const handleFileInput = e => {
    e.persist()
    const file = e.target.files[0]
    setData(prevData => ({
      ...prevData,
      cv: file,
    }))
  }

  useEffect(() => {
    if (!captcha) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [])

  const handleSubmit = () => {
    const formData = {
      ...data,
      is_active: true,
      client: Number(clientId),
      birth_date: formatDateToISOString(data?.birth_date ?? ""),
      cv: data?.cv ?? "",
    }

    createApplication({ data: formData, CLIENT_ID: clientId })
      .unwrap()
      .then(() => {
        toast.success("¡Mensaje enviado correctamente!")
        setData(blankData)
      })
      .catch(error => toast.error("Error al enviar al formulario"))
  }

  return (
    <div
      className={
        "position-relative mediajobs-form" + (stateProp ? " active" : "")
      }
      id={`form-id-${jobData?.id}`}
    >
      <Container fluid>
        <Row>
          <Col xs={12} className="text-end my-3">
            <CloseButton
              className="my-2 my-0 mt-lg-5 mb-lg-3"
              onClick={() => closeBtnAction()}
            />
          </Col>
          <Col xs={12} lg={6} className="pe-lg-5 mb-4">
            <Form
              onSubmit={e => {
                e.preventDefault()
                handleSubmit()
              }}
              className="content-form"
              id="jobsForm"
            >
              <Row className="my-3">
                <h4>Datos personales</h4>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} xs={12} lg={6} controlId="formGridName">
                  <Form.Label className="mb-1">
                    Nombre y apellido<sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    name="first_name"
                    value={data?.first_name ?? ""}
                    type="text"
                    placeholder="Ingresar..."
                    onChange={handleInputChange}
                    autoComplete="off"
                    autoFocus
                    required
                    style={{ borderRadius: "var(--customBorders)" }}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridGender">
                  <Form.Label className="mb-1">Género</Form.Label>
                  <Form.Select
                    name="gender"
                    value={data?.gender ?? ""}
                    defaultValue="No deseo especificar"
                    onChange={handleInputChange}
                  >
                    <option value="No deseo especificar">
                      No deseo especificar
                    </option>
                    <option value="Hombre">Hombre</option>
                    <option value="Mujer">Mujer</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  xs={12}
                  lg={6}
                  controlId="formGridDate"
                  className="mt-3 mt-lg-0"
                >
                  <Form.Label className="mb-1">Fecha de nacimiento</Form.Label>
                  <Form.Control
                    name="birth_date"
                    type="date"
                    onChange={handleDateChange}
                    value={data?.birth_date ?? ""}
                    autoComplete="off"
                    style={{ cursor: "pointer" }}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  lg={6}
                  controlId="formGridSurname"
                  className="mt-3 mt-lg-0"
                >
                  <Form.Label className="mb-1">Lugar de residencia</Form.Label>
                  <Form.Control
                    name="residence"
                    value={data?.residence ?? ""}
                    type="text"
                    placeholder="Ingresar..."
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Row>
              <Row className="my-3 mt-5">
                <h4>Datos de contacto</h4>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} xs={12} lg={6} controlId="formGridEmail">
                  <Form.Label className="mb-1">
                    Email<sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Ingresar..."
                    onChange={handleInputChange}
                    value={data?.email ?? ""}
                    autoComplete="off"
                    required
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  xs={12}
                  lg={6}
                  controlId="formGridPhone"
                  className="mt-3 mt-lg-0"
                >
                  <Form.Label className="mb-1">Whatsapp</Form.Label>
                  <Form.Control
                    name="phone"
                    value={data?.phone ?? ""}
                    type="text"
                    placeholder="Ingresar..."
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Row>
              <Row className="my-3 mt-5">
                <h4>Datos adicionales</h4>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} xs={12} controlId="formGridWp">
                  <Form.Label className="mb-1">
                    Curriculum vitae / CV (Opcional). Archivos compatibles:{" "}
                    <sup>*</sup>pdf, <sup>*</sup>
                    doc, <sup>*</sup>jpg
                  </Form.Label>
                  <Form.Control
                    name="cv"
                    type="file"
                    onChange={e => handleFileInput(e)}
                    autoComplete="off"
                    style={{ cursor: "pointer" }}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  xs={12}
                  lg={12}
                  controlId="formGridLetter"
                  className="mt-3 mt-lg-0"
                >
                  <Form.Label className="mb-1">
                    Carta de presentación (opcional)
                  </Form.Label>
                  <textarea
                    name="letter"
                    value={data?.letter ?? ""}
                    cols="30"
                    rows="5"
                    onChange={handleInputChange}
                    placeholder="Escribe una carta de presentación o cualquier información adicional que nos quieras compartir..."
                  ></textarea>
                </Form.Group>
              </Row>
              <Row className="mb-3 mt-4">
                <Form.Group as={Col} xs={12} controlId="formGridCaptcha">
                  {captcha ? (
                    <ReCAPTCHA
                      sitekey={captcha}
                      onChange={() => setDisabled(false)}
                    />
                  ) : null}
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6} controlId="formGridSubmit">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 mt-4"
                    disabled={disabled || isLoadingApplication}
                  >
                    {isLoadingApplication ? "Enviando..." : "Aplicar"}
                  </button>
                </Form.Group>
              </Row>
            </Form>
          </Col>
          <Col lg={6} className="d-none d-lg-block ps-lg-5 form-text">
            <h4 className="fw-bolder">{`Acerca de ${clientName}`}</h4>
            <br />
            <p className="fw-bolder">{`¡Sumate al Equipo de ${clientName}!`}</p>
            <p className="opacity-text">{formText}</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default connect()(FormJobs)
